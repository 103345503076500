import { sortAlphabetical } from "@biblioteksentralen/utils";
import {
  DateHelper,
  Period,
  SIMPLE_ISO_DATE_FORMAT,
  WEEKDAYS,
  WEEKDAY_LABELS,
  Weekday,
  getNorwegianWeekday,
  getPeriodEnd,
  getWeekdayIndex,
  isValidPeriod,
  positiveModulo,
  timeIsAfter,
} from "@libry-content/common";
import {
  Library,
  OpeningHours,
  OpeningHoursDay,
  OpeningHoursTimeSpan,
  SanityKeyed,
  SelfServiceOpeningHours,
  SelfServiceOpeningHoursDay,
  Site,
  SpecialOpeningHours,
} from "@libry-content/types";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import differenceInWeeks from "date-fns/differenceInWeeks";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import eachMonthOfInterval from "date-fns/eachMonthOfInterval";
import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import { unique } from "radash";
import type { DayOfWeek, OpeningHoursSpecification } from "schema-dts";
import { Translate } from "../../../utils/hooks/useTranslation";
import { getNextTimespanTransition } from "./getTimespan";

export const SCHEMA_ORG_WEEKDAYS: Record<Weekday, DayOfWeek> = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};

interface MakeOpeningHoursSpecificationsProps {
  closed?: boolean;
  spans?: OpeningHoursTimeSpan[];
  dayOfWeek?: DayOfWeek;
  validFrom?: string;
  validThrough?: string;
}

type NormalHoursForDate = Pick<OpeningHoursDay, "spans" | "closed"> &
  (
    | {
        isSpecial: false;
      }
    | {
        isSpecial: true;
        usualHoursForWeekday?: Pick<OpeningHoursDay, "spans" | "closed">;
        note?: string;
      }
  );

type SelfServiceHoursForDate = Pick<SelfServiceOpeningHoursDay, "spans" | "enabled"> &
  (
    | {
        isSpecial: false;
      }
    | {
        isSpecial: true;
        usualHoursForWeekday?: Pick<SelfServiceOpeningHoursDay, "spans" | "enabled">;
        note?: string;
      }
  );

export interface OpeningHoursForDate {
  normalHours?: NormalHoursForDate;
  selfService?: SelfServiceHoursForDate;
  // Indikerer om det er lagt inn avvik for den aktuelle datoen
  divergentHoursForDate: boolean;
}

// Use this to indicate that list has been vetted as valid period, to avoid filtering again
export type SpecialHoursList = SanityKeyed<SpecialOpeningHours & Period>[];

const compareSpansByOpens = (span1: OpeningHoursTimeSpan, span2: OpeningHoursTimeSpan) =>
  !span1?.opens || !span2?.opens || timeIsAfter(span1.opens, span2.opens) ? 1 : -1;

export class OpeningHoursHelper {
  private openingHours?: OpeningHours;
  private selfService?: SelfServiceOpeningHours;
  private specialOpeningHours?: SanityKeyed<SpecialOpeningHours>[];
  private siteHasSelfService: boolean;

  constructor(
    site: Pick<Site, "hasSelfService"> | undefined,
    library?: Pick<Library, "openingHours" | "selfServiceOpeningHours" | "specialOpeningHours">
  ) {
    this.openingHours = library?.openingHours;
    this.specialOpeningHours = library?.specialOpeningHours;
    this.siteHasSelfService = !!site?.hasSelfService;
    this.selfService = this.siteHasSelfService ? library?.selfServiceOpeningHours : undefined;
  }

  static orderSpans(spans?: OpeningHoursTimeSpan[]): OpeningHoursTimeSpan[] {
    return spans?.slice().sort(compareSpansByOpens) ?? [];
  }

  private static openingHoursToString(openingHoursDay?: OpeningHoursDay) {
    return openingHoursDay?.closed
      ? "stengt"
      : OpeningHoursHelper.orderSpans(openingHoursDay?.spans)
          ?.map((span) => `${span.opens}-${span.closes}`)
          .join(" & ");
  }

  private static selfServiceOpeningHoursToString(openingHoursDay?: SelfServiceOpeningHoursDay) {
    return !openingHoursDay?.enabled
      ? "stengt"
      : OpeningHoursHelper.orderSpans(openingHoursDay?.spans)
          ?.map((span) => `${span.opens}-${span.closes}`)
          .join(" & ");
  }

  static normalHoursEqual(normalHours: (OpeningHoursDay | undefined)[]) {
    return unique(normalHours, this.openingHoursToString).length === 1;
  }

  static selfServiceHoursEqual(selfServiceHours: (SelfServiceOpeningHoursDay | undefined)[]) {
    return unique(selfServiceHours, this.selfServiceOpeningHoursToString).length === 1;
  }

  static specialHoursIncludesDate(specialHours: SpecialOpeningHours & Period, date: Date): boolean {
    return (
      new DateHelper(specialHours.from).isSameDateOrBefore(date) &&
      new DateHelper(getPeriodEnd(specialHours)).isSameDateOrAfter(date)
    );
  }

  static getFirstOccurenceOfWeekday(startDate: Date, weekday: Weekday): Date {
    const startDateDayIndex = getWeekdayIndex(getNorwegianWeekday(startDate));
    const relativeDayIndex = positiveModulo(getWeekdayIndex(weekday) - startDateDayIndex, 7);
    return addDays(startDate, relativeDayIndex);
  }

  public sortedWeekOpeningHours({ t, lang }: Translate) {
    const today = DateHelper.now();
    const nextSevenDays = eachDayOfInterval({ start: today, end: addDays(today, 6) });

    return nextSevenDays.map((date) => {
      const weekday = getNorwegianWeekday(date);
      // Å sjekke om det er today eller tomorrow på denne måten fikser bug i pipeline med at tester feilet rundt midnatt
      const isToday = getNorwegianWeekday(DateHelper.now()) === weekday;
      const isTomorrow = getNorwegianWeekday(addDays(DateHelper.now(), 1)) === weekday;
      const label = isToday ? t("I dag") : isTomorrow ? t("I morgen") : WEEKDAY_LABELS[lang][weekday];

      return {
        ...this.openingHoursForDate(date),
        isToday,
        weekday,
        label,
        date,
      };
    });
  }

  public get hasSelfService() {
    if (!this.siteHasSelfService) return false;
    const today = DateHelper.now();

    return eachDayOfInterval({ start: today, end: addDays(today, 6) }).some((date) => {
      const weekday = getNorwegianWeekday(date);
      const specialOpeningHours = this.specialOpeningHoursForDate(date);
      return !!(specialOpeningHours?.selfServiceHours?.[weekday]?.enabled || this.selfService?.[weekday]?.enabled);
    });
  }

  private specialOpeningHoursForDate(date: Date): SpecialOpeningHours | undefined {
    return this.specialOpeningHours
      ?.filter(isValidPeriod)
      ?.find((specialHours) => OpeningHoursHelper.specialHoursIncludesDate(specialHours, date));
  }

  private normalHoursForDate(date: Date): NormalHoursForDate | undefined {
    const weekday = getNorwegianWeekday(date);
    const weekdayNormalHours = OpeningHoursHelper.removeSpansFromNormalhoursIfClosed(this.openingHours?.[weekday]);
    const specialHours = this.specialOpeningHoursForDate(date);
    const dateSpecialHours = OpeningHoursHelper.removeSpansFromNormalhoursIfClosed(
      specialHours?.normalHours?.[weekday]
    );

    const isDivergent =
      dateSpecialHours && !OpeningHoursHelper.normalHoursEqual([weekdayNormalHours, dateSpecialHours]);

    if (isDivergent) {
      return {
        ...dateSpecialHours,
        usualHoursForWeekday: weekdayNormalHours,
        note: specialHours?.note,
        isSpecial: true,
      };
    }

    return {
      ...weekdayNormalHours,
      isSpecial: false,
    };
  }

  private selfServiceOpeningHoursForDate(date: Date): SelfServiceHoursForDate | undefined {
    if (!this.siteHasSelfService) return undefined;

    const weekday = getNorwegianWeekday(date);
    const weekdaySelfServiceHours = OpeningHoursHelper.removeSpansFromSelfserviceHoursIfNotEnabled(
      this.selfService?.[weekday]
    );
    const specialHours = this.specialOpeningHoursForDate(date);
    const dateSpecialHours = OpeningHoursHelper.removeSpansFromSelfserviceHoursIfNotEnabled(
      specialHours?.selfServiceHours?.[weekday]
    );

    const isDivergent =
      dateSpecialHours && !OpeningHoursHelper.selfServiceHoursEqual([weekdaySelfServiceHours, dateSpecialHours]);

    if (isDivergent) {
      return {
        ...dateSpecialHours,
        usualHoursForWeekday: weekdaySelfServiceHours,
        note: specialHours?.note,
        isSpecial: true,
      };
    }

    return {
      ...weekdaySelfServiceHours,
      isSpecial: false,
    };
  }

  public openingHoursForDate(date: Date): OpeningHoursForDate {
    const normalHours = this.normalHoursForDate(date);
    const selfService = this.selfServiceOpeningHoursForDate(date);

    return {
      normalHours,
      selfService,
      divergentHoursForDate: !!(normalHours?.isSpecial || selfService?.isSpecial),
    };
  }

  public get todaysOpeningHours() {
    return this.openingHoursForDate(DateHelper.now());
  }

  public get tomorrowsOpeningHours() {
    return this.openingHoursForDate(DateHelper.daysFromNow(1));
  }

  public get nextTransition() {
    return getNextTimespanTransition(this);
  }

  public upcomingSpecialHours(startDate: Date = new Date()): SpecialHoursList | undefined {
    const startDateAsTimeString = format(startDate, SIMPLE_ISO_DATE_FORMAT);

    return (
      this?.specialOpeningHours
        // Filter away invalid special hours
        ?.filter(isValidPeriod)
        // Filter away special hours entirely in the past
        .filter((specialHours) => new DateHelper(getPeriodEnd(specialHours)).isSameDateOrAfter(startDate))
        // Trim dates so that earlier ones are not included
        .map(({ from, ...rest }) => ({
          from: new DateHelper(from!).isBefore(startDate) ? startDateAsTimeString : from,
          ...rest,
        }))
        // Sort by beginning of interval
        .sort((a, b) => (a.from! > b.from! ? 1 : -1))
    );
  }

  public getSpecialHoursForNextDays(nDays: number): SpecialHoursList | undefined {
    const nDaysFromNow = DateHelper.daysFromNow(nDays);

    return (
      this.upcomingSpecialHours()
        // Since we know the list of special hours is upcoming, it suffices to
        // check that they are the same day as, or before, the end of the interval
        ?.filter(({ from }) => new DateHelper(from!).isSameDateOrBefore(nDaysFromNow))
        // Trim dates so that those pase nDays are not included
        .map(({ to, ...rest }) => ({
          to: new DateHelper(to!).isSameDateOrBefore(nDaysFromNow) ? to : format(nDaysFromNow, SIMPLE_ISO_DATE_FORMAT),
          ...rest,
        }))
    );
  }

  private makeOpeningHoursSpecifications({
    closed,
    spans,
    dayOfWeek,
    validFrom,
    validThrough,
  }: MakeOpeningHoursSpecificationsProps) {
    const baseProps = <OpeningHoursSpecification>{
      "@type": "OpeningHoursSpecification",
      validFrom,
      validThrough,
      ...(dayOfWeek ? { dayOfWeek } : {}),
    };
    return closed
      ? [baseProps]
      : (spans || []).map(({ opens, closes }) => ({
          ...baseProps,
          opens,
          closes,
        }));
  }

  public get schemaOrgOpeningHours() {
    return WEEKDAYS.flatMap((weekday) =>
      this.makeOpeningHoursSpecifications({
        dayOfWeek: SCHEMA_ORG_WEEKDAYS[weekday],
        ...this.openingHours?.[weekday],
      })
    );
  }

  public get schemaOrgSpecialOpeningHours(): OpeningHoursSpecification[] {
    return (this.upcomingSpecialHours() || []).flatMap((spec) => {
      const startDate = new Date(spec.from);
      const endDate = new Date(spec.to || spec.from);
      const openingHours = spec.normalHours;

      // Single day
      if (!spec.isInterval) {
        return this.makeOpeningHoursSpecifications({
          validFrom: format(startDate, "yyyy-MM-dd"),
          validThrough: format(startDate, "yyyy-MM-dd"),
          spans: openingHours?.[getNorwegianWeekday(startDate)]?.spans,
          closed: openingHours?.[getNorwegianWeekday(startDate)]?.closed,
        });
      }

      // < 1 week
      if (differenceInWeeks(endDate, startDate) < 1) {
        return eachDayOfInterval({ start: startDate, end: endDate }).flatMap((date) => {
          return this.makeOpeningHoursSpecifications({
            validFrom: format(date, "yyyy-MM-dd"),
            validThrough: format(date, "yyyy-MM-dd"),
            spans: openingHours?.[getNorwegianWeekday(date)]?.spans,
            closed: openingHours?.[getNorwegianWeekday(date)]?.closed,
          });
        });
      }

      // >= 1 week
      return WEEKDAYS.flatMap((weekday) => {
        return this.makeOpeningHoursSpecifications({
          validFrom: format(startDate, "yyyy-MM-dd"),
          validThrough: format(endDate, "yyyy-MM-dd"),
          dayOfWeek: SCHEMA_ORG_WEEKDAYS[weekday],
          spans: openingHours?.[weekday]?.spans,
          closed: openingHours?.[weekday]?.closed,
        });
      });
    });
  }

  private static sortByFromDate(specialHours: SpecialHoursList) {
    return sortAlphabetical(specialHours, ({ from }) => from);
  }

  // Have to take into account periods that span several months
  public specialOpeningHoursGroupedByMonth(startDate: Date, nMonthsAhead: number = 6) {
    if (nMonthsAhead > 11) {
      console.error(`OpeningHoursHelper.specialOpeningHoursGroupedByMonth can't look ${nMonthsAhead} ahead`);
      return {};
    }

    const specialOpeningHours = this.upcomingSpecialHours(startDate) ?? [];
    const monthCutoffDate = lastDayOfMonth(addMonths(new Date(), nMonthsAhead));

    return (
      OpeningHoursHelper.sortByFromDate(specialOpeningHours)
        // Filter out special opening hours completely outside the cutoff
        .filter(({ from }) => !isAfter(new Date(from), monthCutoffDate))
        .reduce((groups, specialHours) => {
          const periodStart = new Date(specialHours.from);
          const periodEnd = new Date(getPeriodEnd(specialHours));

          const updatedGroups = eachMonthOfInterval({ start: periodStart, end: periodEnd }).reduce(
            (acc, firstOfMonth) => {
              // Filter out outside months for special opening hours partly outside the cutoff
              if (firstOfMonth > monthCutoffDate) return acc;
              const monthNumber = getMonth(firstOfMonth);

              // Truncate at start/end of month if period goes outside
              const lastOfMonth = lastDayOfMonth(firstOfMonth);
              const truncatedFromDate = isBefore(periodStart, firstOfMonth) ? firstOfMonth : periodStart;
              const truncatedToDate = isAfter(periodEnd, lastOfMonth) ? lastOfMonth : periodEnd;

              const truncatedFromString = format(truncatedFromDate, SIMPLE_ISO_DATE_FORMAT);
              const truncatedToString = format(truncatedToDate, SIMPLE_ISO_DATE_FORMAT);
              const truncatedSpecialHours = { ...specialHours, from: truncatedFromString, to: truncatedToString };

              return { ...acc, [monthNumber]: [...(groups[monthNumber] ?? []), truncatedSpecialHours] };
            },
            {}
          );

          return { ...groups, ...updatedGroups };
        }, {} as Record<number, SpecialHoursList>)
    );
  }

  static removeSpansFromNormalhoursIfClosed<T extends Pick<OpeningHoursDay, "closed" | "spans"> | undefined>(
    openingHoursDay: T
  ): T {
    if (!openingHoursDay) return openingHoursDay;

    return {
      ...openingHoursDay,
      closed: !!openingHoursDay?.closed,
      spans: openingHoursDay?.closed ? undefined : openingHoursDay?.spans,
    };
  }

  static removeSpansFromSelfserviceHoursIfNotEnabled<
    T extends Pick<SelfServiceOpeningHoursDay, "enabled" | "spans"> | undefined
  >(selfServiceOpeninghoursDay: T): T {
    if (!selfServiceOpeninghoursDay) return selfServiceOpeninghoursDay;

    return {
      ...selfServiceOpeninghoursDay,
      enabled: !!selfServiceOpeninghoursDay?.enabled,
      spans: selfServiceOpeninghoursDay?.enabled ? selfServiceOpeninghoursDay?.spans : undefined,
    };
  }
}
