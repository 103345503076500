import { EditorLocale } from "@libry-content/localization";
import { Site } from "@libry-content/types";

const selfServiceLabels: Record<"meråpent" | "selvbetjent", Record<EditorLocale, string>> = {
  meråpent: { nb: "meråpent", nn: "meirope", de: "" }, // TODO, tysk oversettelse her
  selvbetjent: { nb: "selvbetjent", nn: "sjølvbetent", de: "" },
};

// Due to issues using "next-translate/getT" in external api route, we hardcode translations here
export const getSelfServiceLabel = (languageCode: EditorLocale, site?: Pick<Site, "selfServiceLabel">): string =>
  site?.selfServiceLabel === "meråpent"
    ? selfServiceLabels.meråpent[languageCode]
    : selfServiceLabels.selvbetjent[languageCode];
