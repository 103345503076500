import { OpeningHoursTimeSpan } from "@libry-content/types";
import { DateHelper } from "@libry-content/common";
import { OpeningHoursHelper } from "./openingHoursHelper";

interface TimespanWithType extends Pick<OpeningHoursTimeSpan, "opens" | "closes"> {
  type: "selfService" | "normal";
}

export const opensComparator = (a: { opens?: string }, b: { opens?: string }): number => {
  const aOpens = a.opens ?? "00:00";
  const bOpens = b.opens ?? "00:00";
  if (aOpens === bOpens) return 0;
  return aOpens > bOpens ? 1 : -1;
};

const beforeOrSameAsNow = (time?: string): boolean => DateHelper.fromTime(time ?? "24:00").isSameOrBeforeNow();
const afterNow = (time?: string): boolean => DateHelper.fromTime(time ?? "00:00").isAfterNow();

function getAllTimespans(helper: OpeningHoursHelper) {
  const { normalHours, selfService } = helper.todaysOpeningHours;
  const { hasSelfService } = helper;

  const normalHourSpans: TimespanWithType[] =
    (!normalHours?.closed && normalHours?.spans?.map((span): TimespanWithType => ({ type: "normal", ...span }))) || [];
  const selfServiceSpans: TimespanWithType[] =
    (hasSelfService &&
      selfService?.enabled &&
      selfService?.spans?.map((span): TimespanWithType => ({ type: "selfService", ...span }))) ||
    [];

  const allSpans: TimespanWithType[] = [...normalHourSpans, ...selfServiceSpans];
  return allSpans.sort(opensComparator);
}

type TimespanType = TimespanWithType["type"];

/**
 * We defined a preference order in case there are several timespans at the same time
 */
const timespanPreferenceOrder: TimespanType[] = ["normal", "selfService"];

/**
 * Compare timespans in such a way that more preferred spans appear later in the list,
 * in order to combine well with the ordering of `getAllTimespans()` in `getCurrentTimespans()`
 */
const compareTimespanPreference = (span1: TimespanWithType, span2: TimespanWithType) =>
  timespanPreferenceOrder.indexOf(span2.type) - timespanPreferenceOrder.indexOf(span1.type);

const orderTimespansByPreference = (spans: TimespanWithType[]) => [...spans].sort(compareTimespanPreference);

/**
 * Returns a list/stack of timespans, where all timespans are within the current time.
 * Within the same preference order, the last item is the timespan that opened last.
 */
export function getCurrentTimespans(helper: OpeningHoursHelper): TimespanWithType[] {
  const alltimespans = getAllTimespans(helper);
  const currentTimespans = alltimespans.filter((span) => beforeOrSameAsNow(span.opens) && afterNow(span.closes));

  return orderTimespansByPreference(currentTimespans);
}

export function getCurrentTimespan(helper: OpeningHoursHelper): TimespanWithType | undefined {
  return getCurrentTimespans(helper).slice(-1)[0];
}

/**
 * Have to make sure the next timespan isn't preferred, otherwise e.g. the following setup:
 * normal hours: 08:00-18:00, self service: 13:00-23:00
 * will indicate "closing soon" from 14:00
 */
const isPreferredLess = (type1: TimespanType, type2: TimespanType) =>
  compareTimespanPreference({ type: type1 }, { type: type2 }) < 0;

export function getNextTimespan(helper: OpeningHoursHelper): TimespanWithType | undefined {
  const alltimespans = getAllTimespans(helper);
  const currentTimespan = getCurrentTimespan(helper);
  const opensNext: TimespanWithType | undefined = alltimespans.filter((span) => afterNow(span.opens))[0];

  if (!currentTimespan) return opensNext;

  // A difference less then 1 minute we assume that they overlap
  if (
    opensNext &&
    DateHelper.timeStringDifferenceInMinutes(opensNext.opens, currentTimespan?.closes) <= 1 &&
    !isPreferredLess(opensNext.type, currentTimespan.type)
  ) {
    return opensNext;
  }

  // if there is no timespan opening imediately after the current one closes we pop timespans from the currentTimespans-stack to see which timespan we "fall into" next.
  const subsequentTimespansNotOpeningInFuture = getCurrentTimespans(helper).filter(({ closes, type }) => {
    const differenceMinutes = DateHelper.timeStringDifferenceInMinutes(closes, currentTimespan?.closes);
    const closesAfterCurrentTimespan = differenceMinutes > 0;

    return closesAfterCurrentTimespan && !isPreferredLess(type, currentTimespan.type);
  });

  return orderTimespansByPreference(subsequentTimespansNotOpeningInFuture).slice(-1)[0];
}

export type Admittance = TimespanWithType["type"] | "closed";

export interface TimespanTransition {
  currentTimespan?: TimespanWithType;
  nextTimespan?: TimespanWithType;
  nextState: Admittance;
  currentState: Admittance;
  happensAt?: string;
}

function happensAt(nextTimespan?: TimespanWithType, currentTimespan?: TimespanWithType): string | undefined {
  if (!currentTimespan) return nextTimespan?.opens;
  if (!nextTimespan) return currentTimespan.closes;

  const nextTimespanHasAllreadyOpened = DateHelper.fromTime(nextTimespan.opens).isBefore(
    DateHelper.fromTime(currentTimespan.opens)
  );
  if (nextTimespanHasAllreadyOpened) return currentTimespan.closes;

  return nextTimespan.opens;
}

export function getNextTimespanTransition(helper: OpeningHoursHelper): TimespanTransition {
  const currentTimespan = getCurrentTimespan(helper);
  const nextTimespan = getNextTimespan(helper);

  return {
    currentTimespan,
    nextTimespan,
    currentState: currentTimespan?.type ?? "closed",
    nextState: nextTimespan?.type ?? "closed",
    happensAt: happensAt(nextTimespan, currentTimespan),
  };
}
